import ic_brain from '../../assets/icons/ic_brain.png'

import { Button, Input, Image } from "antd";
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback, memo } from "react";
import { createUseStyles } from "react-jss";


const CompanyLogo = ({ }) => {

    const [isMouseOver, setIsMouseOver] = useState(true)

    const style = useStyle({ isMouseOver })



    useEffect(() => {

    }, [])


    return (
        <>
            <div className={style.brainButtonContainer} onMouseOver={() => { setIsMouseOver(true) }} onMouseOut={() => { setIsMouseOver(true) }}  >
                <img src={ic_brain} className={style.brainButtonImage} />
            </div>
            <div className={style.overViewContainer} onClick={() => { }} >

                <div className={style.overViewInnerContainer} >
                    <text style={{ fontSize: '1.6vh', color: '#429EFA', fontWeight: 'bold' }}>Types of skins</text>
                    <text style={{ fontSize: '1.6vh', color: 'black', fontWeight: 'bold' }}>Types de peaux</text>
                </div>
            </div>
        </>
    );
}


const useStyle = createUseStyles({
    brainButtonContainer: {
        background: '#FFFFFF30',
        // padding: '1%',
        borderRadius: '100%',
        // top: '1.5vh',
        // left: '1.5vw',
        // position: 'absolute',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'space-between',
        // zIndex: 1000,
        cursor: 'pointer',
        webkitBoxShadow: "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
        mozBoxShadow: "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
        boxShadow: "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
        marginLeft: "12px",
        marginTop: "10px",
        zIndex : 9


    },
    brainButtonImage: {
        width: '7vh',
        height: '7vh',
        objectFit: 'contain',
        alignSelf: 'center'
    },
    overViewContainer: {
        width: '20vh',
        height: '7vh',
        background: '#FFFFFF30',
        borderRadius: 100,
        top: '1.2vh',
        left: (props) => props.isMouseOver ? '1.5vw' : '-50vw',
        position: 'absolute',
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        transition: "left 0.3s ease-in-out",
        flexDirection: 'row',
    },
    overViewInnerContainer: {
        width: '15vh',
        height: '6.5vh',
        background: 'transparent',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "left 0.5s ease-in-out",
        flexDirection: 'column',
        borderRadius: 100,

    }
})

export default memo(CompanyLogo);
